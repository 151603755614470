<template>
  <div id="page-top-company-view">
    <b-card
      title=""
      class="mb-2"
      hide-footer
      body-class="pa-0"
    >
      <b-row>
        <b-col cols="4">

          <b-form-group
            label="Status"
          >
            <Multiselect
              v-model="selected_status"
              class="multiselect-blue mr-sm-2 mx-0"
              value-prop="code"
              :close-on-select="false"
              :searchable="false"
              :options="status_options_select"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-row>
        <b-col cols="4">
          <b-input-group class="mb-4">
            <b-form-input
              v-model="search"
              type="search"
              style="max-width: 380px;"
              placeholder="Sök förening"
            />
            <b-input-group-prepend is-text>
              <inline-svg src="/assets/svg/search.svg"/>
            </b-input-group-prepend>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6" >
          <div class="bottom-alignment">
            <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
          </div>

        </b-col>
        <b-col cols="6">
          <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

            <b-form-select
              class="mr-sm-2 mt-2 align-bottom"
              style="max-width: 100px;"
              :options="[100, 500, 1000]"
              v-model="per_page"
            />

            <b-pagination
              class="mb-0 mt-2 align-bottom"
              style="padding-left: 0px !important;"
              v-model="current_page"
              :total-rows="total_rows"
              :per-page="per_page"
              first-number
              last-number
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>

          <b-table
            style="width: 100%; table-layout: fixed;"
            class="table-striped"
            head-variant="light"
            hover
            :fields="grant_headers"
            :items="filtered_grants"
            :per-page="per_page"
            :current-page="current_page"
            :tbody-tr-class="row_class"
          >
            <template #cell(created_at)="{ item }">
              <span>{{ formatDate(item.created_at) }}</span>
            </template>
            <template #cell(updated_at)="{ item }">
              <span>{{ formatDate(item.updated_at) }}</span>
            </template>
            <template #cell(action1)="{ item }">
              <a
                href="#"
                class="btn btn-icon btn-light btn-sm mx-3"
                @click="downloadGrantSummary(item.todo_id)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </template>
            <template #cell(action2)="{ item }">
              <b-button
                type="button"
                class="btn btn-sm"
                @click="selectGrantClicked(item.todo_id)"
                variant="primary"
              >Hantera</b-button
              >
            </template>
            <template #cell(status)="{ item }">
              <b-form-select
                id="status"
                v-model="item.status"
                :options="status_options"
                @change="onStatusChanged(item.todo_id, item.status)"
              />
            </template>
            <template #cell(show_details)="row">
              <b-button variant="link" size="sm" @click="row.toggleDetails" class="mr-2">
                <b-icon v-if="row.detailsShowing" icon="chevron-up" />
                <b-icon v-else icon="chevron-down" />
              </b-button>
            </template>
            <template #row-details="{ item }">
              <GrantWizard
                :ref_todo_id="item.todo_id"
              />
            </template>
          </b-table>

        </b-col>
      </b-row>
    </b-card>

  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import GrantTopCompanyChildTable from '@/view/pages/ml/grant/GrantTopCompanyChildTable.vue';
import GrantWizard from '@/view/pages/ml/grantwizard/GrantWizard.vue';

import dayjs from 'dayjs';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';

export default {
  name: 'grant-top-company-view',
  components: {
    GrantTopCompanyChildTable,
    GrantWizard,
    Multiselect,
  },
  mixins: [ toasts ],
  props: ['grants'],
  emits: ['onStatusChanged', 'selectGrantClicked', 'showTodoTaskFilesClicked', 'showTodoTaskCommentsClicked', 'selectTodoTaskClicked', 'deleteTodoTaskClicked', 'updateTodoTaskStatus'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    filtered_grants() {
      return this.grants
        .filter(row => this.custom_filter(row, [this.search, this.selected_status]));
    }
  },
  mounted() {
    for (const s of this.status_options) {
      this.status_options_select.push({ label: s.text, code: s.value });
    }
  },
  data() {
    return {
      filters: {},
      status_options_select: [],
      status_options: [
        { value: 'WAIT', text: 'Handläggs' },
        { value: 'ACC', text: 'Godkänt' },
        { value: 'REJ', text: 'Nekat' },
        { value: 'NOT', text: 'Komplettera' },
        { value: 'DONE', text: 'Klar' },
        { value: 'CLOSE', text: 'Avslutat' },
      ],
      search: null,
      selected_status: null,
      rowsPerPage: [100, 500, 1000, -1],
      current_page: 1,
      per_page: 100,
      total_rows: 0,
      singleExpand: false,
      activeExpanded: [],
      closedExpanded: [],
      editing: false,
      creating: false,
      selectedGrant: null,

      statusName: {
        DRAFT: 'Utkast',
        INVALID: 'Ej giltig',
        WAIT: 'Handläggs',
        NOT: 'Komplettera',
        DONE: 'Klar',
        CN: 'Bekräftat',
        DEL: 'Raderat',
        ACC: 'Accepterat',
        REJ: 'Avslaget',
        BAC: 'Backlog',
        FUT: 'Framtida',
        CLOSE: 'Avslutat'
      },
      headerProps: {
        sortByText: 'Sortera'
      },
      grant_headers: [
        { label: 'Ansökningsnamn', key: 'name', tdClass: 'td-overflow', filterable: false, },
        { label: 'Datum', key: 'created_at', tdClass: 'td-overflow', sortable: true, filterable: false },
        { label: 'Uppdaterat', key: 'updated_at', tdClass: 'td-overflow', filterable: false, },
        {
          label: 'Förening',
          tdClass: 'td-overflow',
          align: 'start',
          sortable: true,
          key: 'creator_company.name',
          filterable: true,
        },
        { label: '# Filer', key: 'num_files', filterable: false, },
        {
          label: '# Chat',
          key: 'num_comments', filterable: false,
        },
        { label: 'Status', key: 'status', filterable: true, },
        { label: this.$t('COMMON.ACTIONS'), key: 'action1', filterable: false },
        { label: '', key: 'action2', filterable: false, },
        { label: '', key: 'show_details', filterable: false, }
      ],
    };
  },
  watch: {
    selected_status(newValue) {
      if (newValue) {
        this.search = newValue.code;
      }
      else {
        this.search = null;
      }
    },
    grants(new_val) {
      this.total_rows = new_val.length;
    },
  },
  methods: {
    row_class(item) {
      switch (item.status) {
        case 'WAIT': return 'table-primary';
        case 'ACC': return 'table-success';
        case 'REJ': return 'table-danger';
        case 'NOT': return 'table-warning';
        case 'DONE': return 'table-secondary';
        case 'CLOSE': return 'table-info';
        default: return null;
      }
    },
    async downloadGrantSummary(todo_id) {
      try {
        const res = await axios.post(`/todo/grantsummary/${todo_id}`, {});
        if (res.status === 201) {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.file.linkstr}`, res.data.file.name);
        }
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
      }
    },

    formatDate(dt) {
      return dayjs(dt).format('YYYY-MM-DD');
    },
    custom_filter(row, filter) {
      const [search_query, selected_status] = filter;
      // Filter by search
      const matches_search = !search_query || row.creator_company?.name?.toLowerCase().includes(search_query?.toLowerCase());
      // Filter by status
      const matches_status = !selected_status || row.status === selected_status;

      return matches_search && matches_status;
    },
    onStatusChanged(todo_id, status) {
      this.$emit('onStatusChanged', todo_id, status);
    },
    selectGrantClicked(todo_id) {
      this.selectedGrant = this.grants.find(item => item.todo_id === todo_id);
      this.$emit('selectGrantClicked', todo_id);
    },

    showTodoTaskFilesClicked(todo_task_id) {
      this.$emit('showTodoTaskFilesClicked', todo_task_id);
    },
    showTodoTaskCommentsClicked(todo_task_id) {
      this.$emit('showTodoTaskCommentsClicked', todo_task_id);
    },
    selectTodoTaskClicked(todo_task_id) {
      this.$emit('selectTodoTaskClicked', todo_task_id);
    },
    deleteTodoTaskClicked(todo_task_id) {
      this.$emit('deleteTodoTaskClicked', todo_task_id);
    },
    updateTodoTaskStatus(todo_task_id, status, todo_task){
      this.$emit('updateTodoTaskStatus', todo_task_id, status, todo_task);
    },

    scrollToClassName(classname) {

      this.$nextTick(() => {
        const el = this.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      });

    },

  }
};
</script>

<style scoped lang="scss">
  
  @import "@/assets/sass/components/forms/_common_modals.scss";

</style>
