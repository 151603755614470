<template>
  <div class="container">
    <memlist-modal size="xl" ref="edit-form" :visible="show_modal" @close="show_modal = false" hide-footer>
      
      <GrantApplicationForm
        class="grant-form"
        ref="grantForm"
        :todo_id="todo_id"
        @closed="show_modal = false"
        @onGrantUpdated="onGrantUpdated"
        @onGrantCreated="onGrantCreated"
        @onGrantPublished="onGrantPublished"
      />
      
    </memlist-modal>
  </div>
</template>

<script>

import GrantApplicationForm from '@/view/pages/ml/grant/GrantApplicationForm.vue';

import { get_domain_url } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'GrantApplicationModal',
  components: {
    GrantApplicationForm
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'customer']),
  
  },
  props: {
    todo_id: {
      type: Number,
      required: false,
      default: null,
    },
  },
 
  data() {
    return {
      show_modal: false,
      saving: false,
    };
  },
  methods: {
    onGrantUpdated(item) {
      this.$emit('onGrantUpdated', item);
    },
    onGrantCreated(item) {
      this.$emit('onGrantCreated', item);
    },
    onGrantPublished(item) {
      this.$emit('onGrantPublished', item);
    },
    show() {
      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    },


  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_tabs.scss";

.container {
  padding: 20px;
}
.checkbox-group {
  display: flex;
  gap: 10px;
}
</style>
