<template>
  <memlist-modal ref="success-popup" hide-footer>
    <div class="row">
      <div class="col-12 text-center">
        <i class="fa fa-check-circle" style="font-size: 42px; color: #00b315;"></i>
      </div>
    </div>
    <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
      <div class="col-12 text-center">
        <h4 class="event-name" style="text-align: center;">{{ header }}</h4>
        <br />
        <p>
          {{ text }}
        </p>
      </div>
    </div>
  </memlist-modal>
</template>
  
<style lang="scss" scoped></style>
  
<script>
export default {
  name: 'success-popup',
  data() {
    return {

    };
  },
  props: {
    header: String,
    text: String
  },
  async mounted() {
    if (this.text === "" || this.text === null) {
      return;
    }
    
    this.show()
  },
  watch: {
    text() {

      if (this.text === "" || this.text === null) {
        return;
      }

      this.show();
    }
  },
  methods: {
    show() {
      this.$refs['success-popup'].show();
    },
    onOk() {
      this.$refs['success-popup'].hide();
    },
  },
  computed: {
    
  }
};
</script>
  