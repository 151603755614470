<template>
  <div id="page-application-view">
    <memlist-card
      title=""
      class="mb-2"
      hide-footer
    >

    <SuccessPopup
      :header="success_header_message"
      :text="success_text_message"
    />


    <GrantApplicationTable
      @select_todo_clicked="select_todo_clicked"
      @create_todo_clicked="create_todo_clicked"
      @delete_todo_clicked="delete_todo_clicked"
      :grants="grants"
    />

    <GrantApplicationModal
      class="grant-form"
      :todo_id="todo_id"
      ref="grantForm"
      @onGrantUpdated="onGrantUpdated"
      @onGrantCreated="onGrantCreated"
      @onGrantPublished="onGrantPublished"
    />

    </memlist-card>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';


import GrantApplicationTable from '@/view/pages/ml/grant/GrantApplicationTable.vue';
import GrantApplicationModal from '@/view/pages/ml/grant/GrantApplicationModal.vue';
import SuccessPopup from '@/view/components/SuccessPopup.vue';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'GrantApplicationView',
  components: {
    GrantApplicationTable,
    GrantApplicationModal,
    SuccessPopup
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.loadCompanyGrants(this.currentCompanyId);
  },
  data() {
    return {
      editing: false,
      creating: false,
      success_header_message: null,
      success_text_message: null,
      todo_id: null,
      selectedGrant: null,
      grants: [
        {
          todo_id: 1,
          name: 'Ansökan 1',
          status: 'DRAFT',
          sum: 1000,
          from_datetime: '2021-02-16 20:00:00',
          descr: 'Detta är lite beskrivande text'
        }
      ]
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.editing = this.creating = false;
        this.loadCompanyGrants(this.currentCompanyId);
      }
    }
  },
  methods: {
    select_todo_clicked(todo_id) {
      this.selectGrant(todo_id);
    },

    selectGrant(todo_id) {
      this.editing = true;

      const todo = this.grants.find(item => item.todo_id === todo_id);
      this.selectedGrant = todo;

      this.$nextTick(() => {
        this.todo_id = todo_id;
        this.$refs['grantForm'].show();
      });

    },

    onGrantPublished(todo) {
      const index = this.grants.findIndex(i => i.todo_id === todo.todo_id);

      if (index >= 0) {
        this.grants[index] = todo;
      }

      this.grants = [ ...this.grants ];
    },

    onGrantCreated(todo) {
      this.grants.push(todo);
      this.creating = false;
    },

    onGrantUpdated(todo) {
      const i = this.grants.findIndex(item => item.todo_id === todo.todo_id);

      if (i < 0) {
        console.error('couldnt find todo: ' + i);
        return;
      }

      this.grants[i] = todo;
      // Vue3 compatability for Vue.set
      this.grants = [...this.grants];

      this.editing = false;
    },

    delete_todo_clicked(todo_id) {
      axios
        .delete(`/todo/${todo_id}`)
        .then(res => {
          if (res.status === 204) {
            if (this.selectedGrant.todo_id === todo_id) {
              this.editing = false;
            }
            this.grants = this.grants.filter(item => item.todo_id !== todo_id);
            this.toastr('success', this.$t('COMMON.OK'), 'Raderat');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte radera');
        });

    },

    create_todo_clicked() {
      this.creating = true;

      this.$nextTick(() => {
        this.$refs['grantForm'].show();
      });

    },

    loadCompanyGrants(company_id) {
      axios
        .get(`/todo/mygrants/${company_id}`)
        .then(res => {
          this.grants = res.data;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista ansökningar');
        });
    },

    scrollToClassName(classname) {

      this.$nextTick(() => {
        const el = this.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      });
    },

  }
};
</script>
